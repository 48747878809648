// @flow
import {HardwareOption, CabinetTop, GapSize, ProductSize} from 'IndexedDB';
import {HandlerBuilder} from 'shared';
import type {Handler} from 'shared';
import Axios from './Axios';

type GetCabinetTopType = {
    availableTops?: number[],
    showNone?: boolean,
};

export const getCabinetTop = async ({
    availableTops = [],
    showNone = false,
}: GetCabinetTopType): Promise<CabinetTop[]> => {
    const handlerBuilder = HandlerBuilder.createInstance('/rooms/cabinet-tops');
    const handler: Handler<CabinetTop> = handlerBuilder
        .setStoreName('cabinet_top')
        .setObjectProcessor((object: Object): CabinetTop =>
            Object.assign(new CabinetTop(), object)
        )
        .build();
    let cabinetTops: CabinetTop[] = await handler.getData();

    if (availableTops.length) {
        cabinetTops = cabinetTops.filter((cabinetTop) =>
            availableTops.includes(cabinetTop.id)
        );

        const noneOption = new CabinetTop();
        noneOption.id = -1;
        noneOption.name = 'None';

        if (showNone) cabinetTops.unshift(noneOption);
    }

    return cabinetTops;
};

export const getHardwareOptions = (): Promise<HardwareOption[]> => {
    const handlerBuilder = HandlerBuilder.createInstance<HardwareOption>(
        'customer/manufacturerHardwareOptions'
    );

    return handlerBuilder
        .setStoreName('manufacturer_hardware_options')
        .setResultName('hardware_options')
        .setObjectProcessor((object: Object): HardwareOption => {
            return Object.assign(new HardwareOption(), object);
        })
        .build()
        .getData();
};

export const getSupplyMethods = async (): Promise<HardwareOption[]> => {
    const supplyMethods = [];
    const hardwareOptions = await getHardwareOptions();

    Array.isArray(hardwareOptions) &&
        hardwareOptions.forEach((option) => {
            option.id = option.value;

            if (option.assembly) {
                supplyMethods.push(option);
            }
        });

    return supplyMethods;
};

export const getHardwareMethods = async (): Promise<HardwareOption[]> => {
    const supplyHardwares = [];
    const hardwareOptions = await getHardwareOptions();

    Array.isArray(hardwareOptions) &&
        hardwareOptions.forEach((option) => {
            option.id = option.value;

            if (option.supplyHardware) {
                supplyHardwares.push(option);
            }
        });

    return supplyHardwares;
};

export const getAdjustableLegsMethods = async (): Promise<HardwareOption[]> => {
    const adjustableLegsMethods = [];
    const hardwareOptions = await getHardwareOptions();

    Array.isArray(hardwareOptions) &&
        hardwareOptions.forEach((option) => {
            option.id = option.value;

            if (option.adjLegs) {
                adjustableLegsMethods.push(option);
            }
        });

    return adjustableLegsMethods;
};

export const getGapSizeDefaults = (
    resetCache: boolean = false
): Promise<GapSize[]> => {
    const handlerBuilder = HandlerBuilder.createInstance(
        'rooms/gap-size-defaults'
    );
    return handlerBuilder
        .setStoreName('gap_sizes_defaults')
        .setObjectProcessor((object: Object): GapSize => {
            object.id = parseInt(object.id);

            return Object.assign(new GapSize(), object);
        })
        .setResetCache(resetCache)
        .build()
        .getData();
};

export const getProductSizeDefaults = (
    resetCache: boolean = false
): Promise<ProductSize[]> => {
    const handlerBuilder = HandlerBuilder.createInstance(
        'rooms/product-size-defaults'
    );
    return handlerBuilder
        .setStoreName('product_sizes_defaults')
        .setObjectProcessor((object: Object): ProductSize => {
            object.id = parseInt(object.id);

            return Object.assign(new ProductSize(), object);
        })
        .setResetCache(resetCache)
        .build()
        .getData();
};

// Api to delete a selected room in a job
export const deleteRoom = async (
    jobId: number,
    roomId: number
): Promise<boolean> => {
    const response = await Axios.instance().delete(
        `jobs/${jobId}/rooms/${roomId}`
    );

    return !!response.data.success;
};

// Api to copy a selected room in a job
export const copyRoom = async (
    jobId: number,
    roomId: number,
    products: boolean
): Promise<boolean> => {
    let response;
    if (products) {
        response = await Axios.instance().get(
            `jobs/${jobId}/rooms/${roomId}/copy?products=false`
        );
    } else {
        response = await Axios.instance().get(
            `jobs/${jobId}/rooms/${roomId}/copy`
        );
    }

    return !!response.data.success;
};

export const saveRoom = async (
    data: FormData,
    jobId: number,
    roomId?: number
): Promise<any> => {
    let url = `jobs/${jobId}/rooms`;

    if (roomId) url += `/${roomId}`;
    const response = await Axios.instance().post(url, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });

    if (response.data.success) {
        return response.data;
    }
};
